<template>
  <app-layout>
    <div dense>
      <v-container grid-list-xs>
        <h3>{{ $t("home") }}</h3>

        <v-row class="pa-0">
          <v-col cols="3" class="">
            <div v-for="(type, index) in types" :key="type">
              <div class="grey pa-2 lighten-1">
                <h5>{{ type }} A/C STATUS</h5>
              </div>
              <el-table
                empty-text="No data"
                :data="aricrafts[index]"
                stripe
                size="small"
                class="md-10"
                style="width: 100%"
                border
              >
                <el-table-column prop="reg_no" label="Reg No">
                </el-table-column>
                <el-table-column prop="log_date" label="Log Date">
                </el-table-column>
              </el-table>
            </div>
          </v-col>

          <v-col cols="9">
            <div class="grey pa-2 lighten-1">
              <h5>{{ $t("openDmiList") }}</h5>
            </div>
            <v-data-table
              :headers="open_dmis_list_headers"
              :items="$store.state.dmiRequestO"
              class="elevation-0"
              dense
            >
              <template v-slot:item.due_date="{ item }">
                <v-chip
                  class="px-2 py-0"
                  label
                  :color="
                    !item.closed_date ||
                    item.closed_date != 'null' ||
                    item.closed_date != null
                      ? getColor(
                          item.data_discovery,
                          getDate(
                            item.due_date
                              ? item.due_date
                              : item.mel
                              ? item.mel
                              : null,
                            item.created_at
                          )
                        )
                      : 'white black--text'
                  "
                  dark
                >
                  {{
                    getDate(
                      item.due_date
                        ? item.due_date
                        : item.mel
                        ? item.mel
                        : null,
                      item.created_at.toString().split(" ")[0]
                    )
                  }}
                </v-chip>
              </template>
            </v-data-table>
            <div class="grey pa-2 lighten-1"><h5>A/C Check Status</h5></div>
            <v-data-table
              :headers="acCheckStatus_headers"
              :items="acCheckStatus"
              class="elevation-0"
              dense
            >
              <template v-slot:item.nex_due="{ item }">
                <v-chip
                  class="px-2 py-0"
                  label
                  :color="getColor(item.output_date, item.nex_due)"
                  dark
                >
                  {{ item.nex_due }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/AppLayout";

export default {
  components: {
    AppLayout,
  },
  data() {
    return {
      tableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
      tableData2: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],

      open_dmis_list_headers: [
        {
          text: "REG NO",
          align: "start",
          sortable: false,
          value: "reg_no",
          width: 90,
        },
        { text: "DUE DATE", value: "due_date" },
        { text: "DMI NO", value: "id" },
        { text: "OPEN DATE", value: "data_discovery" },
        { text: "DESCRIPTION", value: "description" },
        { text: "STN", value: "station" },
        { text: "OP IMPACT", value: "op_impact", width: 40 },
        { text: "AIRLINE DMI", value: "airline_dmi_no" },
      ],
      acCheckStatus_headers: [
        {
          text: "REG NO",
          align: "start",
          sortable: false,
          value: "reg_no",
          width: 90,
        },
        { text: "SEQ", value: "sequence" },
        { text: "SCHEDULE DATE", value: "nex_due" },
        { text: "HOURS LEFT", value: "data_discovery" },
        { text: "CYCLES LEFT", value: "description" },
        { text: "DAYS LEFT", value: "station" },
        { text: "NEXT MONTHLY CHECK", value: "op_impact", width: 200 },
      ],
      acCheckStatus: [],

      types: [],
      aricrafts: [],
    };
  },
  methods: {
    percentagemFromDates(s, e) {
      var start = new Date(s);
      var end = new Date(e);
      var today = new Date();

      //use Math.abs to avoid sign
      var q = today - start;
      var d = end - start;

      /*  console.info('Porcentagem', Math.round(((q/d))*100)) */
      return (q / d) * 100;
    },
    getColor(discovery, dataFinal) {
      if (this.percentagemFromDates(discovery, dataFinal) >= 100) return "red";
      else if (
        this.percentagemFromDates(discovery, dataFinal) < 100 &&
        this.percentagemFromDates(discovery, dataFinal) >= 60
      )
        return "orange";
      else return "green";
    },

    getDate(data, discovery) {
      let dataFinal = data;
      var date = new Date(discovery);

      if (data == "A") {
        date.setDate(date.getDate() + 1);
      }
      if (data == "B") {
        date.setDate(date.getDate() + 3);
      }
      if (data == "C") {
        date.setDate(date.getDate() + 10);
      }
      if (data == "D") {
        date.setDate(date.getDate() + 120);
      }

      var mes = date.getMonth() + 1;
      dataFinal =
        date.getFullYear() +
        "-" +
        (mes.toString().length < 2 ? "0" + mes : mes) +
        "-" +
        (date.getDate().toString().length < 2
          ? "0" + date.getDate()
          : date.getDate());

      return dataFinal;
    },

    groupAC() {
      let dis = this;

      let types = this.$store.state.aircraftType;

      for (let i = 0; i < types.length; i++) {
        const type = types[i];

        dis.types.push(types[i].designation);

        let t = [];

        for (let e = 0; e < dis.$store.state.acInformation.length; e++) {
          const ac = dis.$store.state.acInformation[e];
          if (ac.config == type.designation) {
            t.push(ac);
          }
        }

        dis.aricrafts.push(t);
      }

      /* console.log(dados)
        console.log( dis.aricrafts) */

      /*  this.types = dados; */
    },
  },
  async mounted() {
    this.$store.dispatch("getAcType");
    this.$store.dispatch("getDmiRequestO");
    await this.$store.dispatch("getAircraftInformation");
    this.groupAC();

    let dis = this;
    const axios = require("axios");
    axios.get(this.$store.state.path + "getAcCheckStatus").then((response) => {
      let regNo = [];

      response.data.forEach((el) => {
        if (regNo.indexOf(el.reg_no) == -1) {
          dis.acCheckStatus.push(el);
          regNo.push(el.reg_no);
        }
      });
    });

    var fs = require("fs");
    /* var files = await  fs.readdir('../arquivos'); */

    console.info("Arquivos: ", fs);
  },
  computed: {
    /* Fumcao para agrupar todas as aeronaves */
  },
};
</script>



  