import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    logged: false,
    loading: false,
    dialog: true,
    editing: false,
    editingItem: null,
    to: "",

    user: {},
    defectLog: [],
    users: [],
    acInformation: [],
    aircraftLog: [],
    atl: [],
    defectType: [],
    aircraftType: [],
    taskCard: [],
    dmiRequest: [],
    dmiRequestO: [],
    ac_accumulated_hours_and_cycles: [],

    ata: [],
    ataSection: [],
    ataSection2: [],
    //Local
    /* path: "http://127.0.0.1:8000/api/",
    path2: "http://127.0.0.1:8000/",*/

    //Prod
    path: "https://api-taag.ramossoft.com/api/",
    path2: "https://api-taag.ramossoft.com//",
  },
  mutations: {
    /*  atl(context, item){
      defectLog.defectLog.push(item);
    } */

    logout(state) {
      state.logged = false;
    },
  },
  actions: {
    logout(state) {
      window.sessionStorage.setItem("Username", "null");
      state.defectLog = [];
      (state.user = {}), (state.users = []);
      state.aircraftType = [];
      state.commit("logout");
    },
    ataSection(store, dados = { index: "00", d: 0 }) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "ataSection/" + dados.index)
        .then(function(response) {
          store.dispatch("nloading");
          if (!dados.d) store.state.ataSection = response.data;
          else store.state.ataSection2 = response.data;

          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get ata Section.",
            "error"
          );
          console.info(error);
        });
    },
    defectType(store) {
      if (store.state.defectType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        axios
          .get(store.state.path + "defectType")
          .then(function(response) {
            store.state.defectType = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get deft types.",
              "error"
            );
            console.info(error);
          });
      }
    },
    getAtl(store) {
      /* if (store.state.atl.length <= 0) { */
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "atl")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.atl = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Atl.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    filterAircraftLog(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "atlFilter", dados)
        .then(function(response) {
          store.dispatch("nloading");
          store.state.atl = response.data.data;

          /*  console.info(response.data); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Atl.",
            "error"
          );
          console.info(error);
        });
    },
    filterDefectLog(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "defectLogFilter", dados)
        .then(function(response) {
          store.dispatch("nloading");
          store.state.defectLog = response.data.data;

          /*  console.info(response.data); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Defect Log.",
            "error"
          );
          console.info(error);
        });
    },
    getDefectLog(store) {
      /*  if (store.state.defectLog.length <= 0) { */

      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "defectLog")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.defectLog = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    getAta(store) {
      if (store.state.ata.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "ata")
          .then(function(response) {
            store.dispatch("nloading");
            store.state.ata = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the ata.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getTaskCard(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "taskCard")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.taskCard = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the ata.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getDmiRequestO(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "dmiRequestO")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequestO = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the DMI.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getDmiRequest(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "dmiRequest")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequest = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the ata.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterDMI(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "filterDMI", dados)
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequestO = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the DMIs.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filteACHoursCycles(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "ac_accumulated_hours_and_cyclesFilter", dados)
        .then(function(response) {
          store.dispatch("nloading");
          store.state.ac_accumulated_hours_and_cycles = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the AC Hours and Cycles.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getACHoursCycles(store) {
      const axios = require("axios");

      store.dispatch("loading");

      axios
        .get(store.state.path + "ac_accumulated_hours_and_cycles")
        .then((response) => {
          store.state.ac_accumulated_hours_and_cycles = response.data.data;
          store.dispatch("nloading");
        });

      store.dispatch("nloading");
    },
    getAcType(store) {
      if (store.state.aircraftType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "acType")
          .then(function(response) {
            store.dispatch("nloading");
            store.state.aircraftType = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the ata.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    async getAircraftInformation(store) {
      /* if (store.state.acInformation.length <= 0) { */
      store.dispatch("loading");

      let axios = require("axios");

      await axios
        .get(store.state.path + "acInformation")
        .then(function(response) {
          store.dispatch("nloading");
          store.state.acInformation = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );

          console.info(error);
        })
        .then(function() {
          return "done!";
        });

      return "done";

      /* } */
    },
    getAcByType(store, type) {
      /* if (store.state.acInformation.length <= 0) { */
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .post(store.state.path + "acByType", { config: type })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.acInformation = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    loading(store) {
      store.state.loading = true;
    },
    nloading(store) {
      store.state.loading = false;
    },
    clearEdit(store) {
      store.state.editing = false;
      store.state.editingItem = null;
      store.state.to = "";
    },
  },
  modules: {},
});
