import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import JDTable from "vue-jd-table";
import ElementeUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-jd-table/dist/jd-table.min.css";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;
Vue.component("jdtable", JDTable);
Vue.use(ElementeUI);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
