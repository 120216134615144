<template>
  <v-app id="app" dense>
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet dense class="pa-3">
        <div class="d-flex align-center">
          <v-img max-width="220" src="@/assets/images/taag3.png"> </v-img>
        </div>
      </v-sheet>

      <div></div>
      <v-divider></v-divider>

      <v-list-item route to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title v-text="'Home'"></v-list-item-title>
      </v-list-item>

      <v-list-group
        :value="false"
        no-action
        v-for="menu in menus"
        :key="menu.label"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ menu.label }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-group
          v-show="menu.val1[0].val"
          :value="false"
          no-action
          sub-group
          v-for="item in menu.val1"
          :key="item.label"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-show="item.val"
            v-for="btn in item.val"
            :key="btn.label"
            route
            :to="btn.route"
          >
            <v-list-item-title v-text="btn.label"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-show="!menu.val1[0].val"
          v-for="btn1 in menu.val1"
          :key="btn1.label"
          route
          :to="btn1.route"
        >
          <v-list-item-title v-text="btn1.label"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item route to="/tabelas">
        <v-list-item-icon>
          <v-icon>mdi-table</v-icon>
        </v-list-item-icon>

        <v-list-item-title v-text="'Tabelas'"></v-list-item-title>
      </v-list-item>

      <!-- <v-expansion-panels accordion elevation="0" class="pa-0 ma-0" focusable >
            <v-expansion-panel v-for="menu in menus" :key="menu.label" class="pa-0 ma-0" elevation="0">
                <v-expansion-panel-header class="pa-0 pl-2" elevation="0"><v-icon class="mx-3" style="max-width:35px;">{{menu.icon}}</v-icon>  {{menu.label}}</v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
                        <v-expansion-panels accordion class="pa-0 ma-0" elevation="0" focusable>
                            <v-expansion-panel v-for="item in menu.val1" elevation="0" :key="item.label" class="pa-0 ma-0" >
                                <v-expansion-panel-header elevation="0" class="pa-0 ma-0">{{item.label}}</v-expansion-panel-header>
                                <v-expansion-panel-content elevation="0" class="pa-0 ma-0">

                                    <v-list nav dense v-for="btn in item.val" elevation="0" :key="btn.label" class="pa-0 ma-0 elevation-0">
                                        <v-list-item route :to="btn.route" elevation="0">
                                            <v-list-item-title>{{btn.label}}</v-list-item-title>
                                        </v-list-item>
                                        
                                        </v-list>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels> -->
      <v-list-item @click="$store.dispatch('logout'), $router.push('/login')">
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
    <v-app-bar app dense>
      <v-app-bar-nav-icon
        class="black--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>TAAG-VME</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-model="defaultLanguage"
        :items="idiomas"
        dense
        style="max-width: 200px"
        outlined
        class="mt-5 mr-5"
        solo
        flat
        label="Idioma"
        @change="mudaidioma(defaultLanguage)"
      ></v-select>

      <b>
        <span>{{ timeNow }}</span>
      </b>
      <v-col class="text-right">
        <v-avatar size="32" class="ml-3">
          <img src="@/assets/images/logo.png" alt="User" />
        </v-avatar>
        <span class="ml-2">{{ $store.state.user.username || "User" }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-icon @click="$store.dispatch('logout'), $router.push('/login')"
          >mdi-power</v-icon
        >
      </v-col>
    </v-app-bar>

    <v-main style="background-color: beige">
      <div class="white" style="min-height: 40px">
        <v-container class="py-0 d-flex text-center px-0 align-center">
          <v-row class="pa-0 align-center">
            <v-btn route to="/" color="black" class="f12" text>
              <v-icon color="primary" class="mr-2 f11 t-l">mdi-home</v-icon>
              {{ $t("home") }}</v-btn
            >

            <dropdown
              class="pa-0"
              :trigger="'hover'"
              v-for="(menu, e) in menus"
              :key="menu.label"
            >
              <template slot="btn"
                ><v-btn color="black" class="f12 pa-0 t-l mx-1" block text>
                  <v-icon color="primary" class="mr-2 f11 t-l">{{
                    menu.icon
                  }}</v-icon>
                  {{ e + 1 + ". " + $i18n.t(menu.label) || menu.label }}</v-btn
                ></template
              >
              <template
                slot="body"
                class=""
                :trigger="'hover'"
                :role="'sublist'"
                :align="'right'"
              >
                <div
                  v-for="(val1, i) in menu.val1"
                  :key="val1.label"
                  class="pa-0 d-flex"
                  elevation="0"
                >
                  <v-btn
                    v-if="val1.val == null"
                    slot="btn"
                    class="f12 f12 pa-0 t-l"
                    route
                    :to="val1.route"
                    color="black"
                    text
                    block
                    >{{
                      e + 1 + "." + i + " " + $i18n.t(val1.label) || val1.label
                    }}</v-btn
                  >

                  <dropdown
                    v-else
                    :trigger="'hover'"
                    :role="'sublist'"
                    :align="'right'"
                  >
                    <template slot="btn">
                      <v-btn
                        slot="btn"
                        class="f12 f12 pa-0 t-l"
                        color="black"
                        text
                        block
                        >{{
                          e + 1 + "." + i + " " + $i18n.t(val1.label) ||
                          val1.label
                        }}</v-btn
                      ></template
                    >
                    <template slot="body">
                      <div
                        class="d-flex"
                        v-for="(item, i2) in val1.val"
                        :key="item.label"
                        elevation="0"
                      >
                        <v-btn
                          slot="btn"
                          class="f12 f12 pa-0 t-l"
                          route
                          :to="item.route"
                          color="black"
                          text
                          block
                          >{{
                            e +
                              1 +
                              "." +
                              i +
                              "." +
                              +i2 +
                              " " +
                              $i18n.t(item.label) || item.label
                          }}</v-btn
                        >
                      </div>
                    </template>
                  </dropdown>

                  <ul></ul>
                </div>

                <!-- <ul>
            
              <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                <template slot="btn">Sublist right</template>
                <template slot="body">Sublist content</template>
              </dropdown>
            </ul> -->
              </template>
            </dropdown>

            <v-btn route to="/tabelas" color="black" class="f12" text>
              <v-icon color="primary" class="mr-2 f11"
                >mdi-table-multiple</v-icon
              >
              {{ $t("tables") }}</v-btn
            >
          </v-row>
        </v-container>
      </div>
      <slot />
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        Copyright©{{ new Date().getFullYear() }} —
        <strong
          >TAAG-VME -
          <strong>{{ $t("allRightReservedToTaag") }}</strong></strong
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Dropdown from "bp-vuejs-dropdown";
export default {
  components: {
    Dropdown,
  },

  mounted() {
    this.currentDate();
    this.defaultLanguage =
      localStorage.getItem("idioma") != null ||
      localStorage.getItem("idioma") != ""
        ? localStorage.getItem("idioma")
        : "English";
  },

  methods: {
    mudaidioma(idioma) {
      /* MUDANDO DE IDIOMA */
      localStorage.setItem("idioma", idioma);

      this.$i18n.locale = localStorage.getItem("idioma");
    },
    currentDate() {
      setInterval(() => {
        var currentdate = new Date();
        var datetime =
          currentdate.getDate() +
          "/" +
          (currentdate.getMonth() + 1) +
          "/" +
          currentdate.getFullYear() +
          "  " +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes() +
          ":" +
          currentdate.getSeconds();
        this.timeNow = datetime.toString();
      }, 500);
    },
  },

  data() {
    return {
      defaultLanguage: "English",
      idiomas: ["English", "Português"],
      timeNow: null,
      drawer: true,

      menus: [
        {
          label: "fleetActivity",
          icon: "mdi-airplane",
          val1: [
            {
              label: "flightData",
              route: "/flightData",
              val: [
                { label: "acInformation", route: "/acInformation" },
                { label: "newLogEntry", route: "/newLogEntry" },
                { label: "logTimeCorrection", route: "/aircraftLog" },
                { label: "acLog", route: "/aircraftLog" },
                { label: "acHoursCycles", route: "/aircraftHoursCycles" },
                { label: "apuHoursCycles", route: "/apuHoursCycles" },
                { label: "oilFuelConsumption", route: "/oilFuelConsuption" },
                { label: "acScheduler", route: "/acSchendule" },
                { label: "routeAssignment", route: "/routeAssignment" },
                { label: "atl", route: "/atl" },
              ],
            },
            {
              label: "defLog",
              route: "/acInformation",
              val: [
                { label: "defectLogEntry", route: "/defectLogEntry" },
                { label: "defInfoSearch", route: "/defectLogInfoSearch" },
                { label: "newDmiRequest", route: "/newDmiRequest" },
                { label: "dmiRequestList", route: "/dmiRequestList" },
                { label: "outstandingDmiList", route: "/defectLogInfoSearch" },
                { label: "dmiStatusUpdate", route: "/" },
                {
                  label: "signOffCopyMissingList",
                  route: "/defectLogInfoSearch",
                },
              ],
            },
            {
              label: "partTimes",
              route: "/partTimes",
              val: [
                { label: "D2-TBF", route: "/tbf" },
                { label: "D2-TBG", route: "/tbf" },
                { label: "D2-TBH", route: "/tbf" },
                { label: "D2-TBJ", route: "/tbf" },
                { label: "D2-TBK", route: "/tbf" },
                { label: "D2-TED", route: "/tbf" },
                { label: "D2-TEE", route: "/tbf" },
                { label: "D2-TEF", route: "/tbf" },
                { label: "D2-TEG", route: "/tbf" },
                { label: "D2-TEH", route: "/tbf" },
                { label: "D2-TEI", route: "/tbf" },
                { label: "D2-TEJ", route: "/tbf" },
                { label: "D2-TEK", route: "/tbf" },
              ],
            },
            {
              label: "cabinedefect",
              route: "/acInformation",
              val: [
                { label: "cdl", route: "/tbf" },
                { label: "openDefectsReport", route: "/tbf" },
                { label: "cabinDefectsSearch", route: "/tbf" },
                { label: "closeDeferredItems", route: "/tbf" },
              ],
            },
          ],
        },
        {
          label: "mtn",
          icon: "mdi-hammer-screwdriver",
          val1: [
            {
              label: "mSchedule",
              val: [
                { label: "mTracking", route: "/" },
                { label: "routePackage", route: "/" },
                { label: "routeInformation", route: "/" },
              ],
            },
            {
              label: "mProgram",
              val: [
                { label: "newMRItem", route: "/" },
                { label: "newTaskCard", route: "/newTaskCard" },
                { label: "mrItemList", route: "/" },
                { label: "mrComplance", route: "/" },
                { label: "tcRevisionControl", route: "/" },
                { label: "revisionPendingList", route: "/" },
                { label: "taskCardList", route: "/taskCardList" },
                { label: "mrTcCrossReference", route: "/" },
                { label: "oopForecast", route: "/" },
                { label: "taskCadProperties*", route: "/" },
                { label: "tcLastDone", route: "/tcLastDone" },
              ],
            },
            {
              label: "mtCheck",
              val: [
                { label: "checkPlanning", route: "/checkPlanning" },
                { label: "checkHistory", route: "/checkHistory" },
                { label: "checkPackage", route: "/chekckPackage" },
                { label: "checkSignOff", route: "/checkSignOff" },
                { label: "checkPackagePreload", route: "/" },
                { label: "nextMtCheck", route: "/" },
                { label: "minimumPackageSetup", route: "/" },
                { label: "checkDef", route: "/" },
                { label: "accessPanelList", route: "/" },
              ],
            },
            {
              label: "maintenanceOrder",
              val: [
                { label: "newOrder", route: "/" },
                { label: "issuedOrder", route: "/" },
                { label: "outstandingOrderSearch", route: "/" },
                { label: "orderStatusUpdate", route: "/" },
                { label: "signOffCopyUpload", route: "/" },
                { label: "signOffCopyMissingList", route: "/" },
                { label: "issuedOrderList", route: "/" },
              ],
            },
            {
              label: "mtPlanning",
              val: [
                { label: "planningDesktop", route: "/" },
                { label: "repeaterAlerts", route: "/" },
              ],
            },
          ],
        },
        {
          label: "parts",
          icon: "mdi-arrange-send-to-back",
          val1: [
            {
              label: "parts",
              val: [
                { label: "newRequisitionEntry", route: "/flightData" },
                {
                  label: "outstandingRequisitionEntry",
                  route: "/acInformation",
                },
                { label: "requisitionHistorySearch", route: "/acInformation" },
                { label: "partsSearch", route: "/acInformation" },
                { label: "partsInstallRemove", route: "/acInformation" },
                { label: "lifeLimitedPartsList", route: "/acInformation" },
                { label: "installedPartsSetup", route: "/acInformation" },
                { label: "partsRemovedReport", route: "/acInformation" },
                { label: "partRequisitionApproval", route: "/acInformation" },
              ],
            },
            {
              label: "stores",
              val: [
                { label: "partReceiving", route: "/flightData" },
                { label: "partsInspection", route: "/acInformation" },
                { label: "partsRapair", route: "/acInformation" },
                { label: "certTagMissingList", route: "/acInformation" },
                { label: "loanerPartsList", route: "/acInformation" },
                { label: "storesInOutReport", route: "/acInformation" },
                { label: "partsScrap", route: "/acInformation" },
                { label: "sotresShelfBinSetup", route: "/acInformation" },
                { label: "partLoanIn", route: "/acInformation" },
                { label: "lookupSapParts", route: "/acInformation" },
                { label: "sapRequisitions", route: "/acInformation" },
                { label: "partsReceivedReport", route: "/acInformation" },
                { label: "lpPartChangeReport", route: "/acInformation" },
              ],
            },
            {
              label: "configuration",
              val: [
                { label: "masterConfig", route: "/flightData" },
                { label: "partNumberConfig", route: "/acInformation" },
                { label: "assemblySetup", route: "/acInformation" },
                { label: "visualNha", route: "/acInformation" },
                { label: "kitTemplates", route: "/acInformation" },
                { label: "kitBuilder", route: "/acInformation" },
                { label: "flyAwayKits", route: "/acInformation" },
                { label: "warehouseSetup", route: "/acInformation" },
              ],
            },
          ],
        },
        {
          label: "documents",
          icon: "mdi-file-document-multiple",
          val1: [
            {
              label: "tecnicalLib",
              route: "/flightData",
              val: [{ label: "engineManuals", route: "/manual" }],
            },
            {
              label: "airlineDocuments",
              route: "/acInformation",
              val: [
                { label: "repairs", route: "/manual" },
                { label: "alterations", route: "/manual" },
                { label: "workscope", route: "/manual" },
                { label: "dfdr", route: "/manual" },
                { label: "misReports", route: "/manual" },
                { label: "maintainenceControlRef", route: "/manual" },
                { label: "training", route: "/manual" },
                { label: "Audits", route: "/manual" },
                { label: "deliveryDocuments", route: "/manual" },
              ],
            },
            {
              label: "acRecords",
              route: "/acInformation",
              val: [{ label: "missingRecords", route: "/manual" }],
            },
          ],
        },
        {
          label: "compliance",
          icon: "mdi-apps-box",
          val1: [
            {
              label: "adsb",
              route: "/flightData",
              val: [
                { label: "newAdEntry", route: "/manual" },
                { label: "adSetup", route: "/manual" },
                { label: "newSbEntry", route: "/manual" },
                { label: "sbSetup", route: "/manual" },
                { label: "sbEvaluationHistory", route: "/manual" },
                { label: "sbEvaluationToDoList", route: "/manual" },
                { label: "sbPendingList", route: "/manual" },
                { label: "sbMasterList", route: "/manual" },
                { label: "adSbCompliance", route: "/manual" },
                { label: "adSbForecast", route: "/manual" },
                { label: "adMasterList", route: "/manual" },
              ],
            },
            {
              label: "reliabilityAnalysis",
              route: "/acInformation",
              val: [
                { label: "addTecnicalDay", route: "/acInformation" },
                { label: "apuInFlightStartReport", route: "/acInformation" },
                { label: "flightHoursPerMonth", route: "/acInformation" },
                { label: "ifsdReport", route: "/acInformation" },
                { label: "reliabilityReportSearch", route: "/acInformation" },
                { label: "addIfsd", route: "/acInformation" },
              ],
            },
            {
              label: "adCompliance",
              route: "/acInformation",
              val: [
                { label: "airframe", route: "/airframe" },
                { label: "engine", route: "/airframe" },
                { label: "appliance", route: "/airframe" },
              ],
            },
            {
              label: "certification",
              route: "/acInformation",
              val: [
                { label: "newCertificate", route: "/airframe" },
                { label: "newTechnician", route: "/airframe" },
                { label: "assignCertificate", route: "/airframe" },
                { label: "viewCertificateAssignments", route: "/airframe" },
                { label: "search", route: "/airframe" },
                { label: "editCertificate", route: "/airframe" },
                { label: "editTechnician", route: "/airframe" },
              ],
            },
          ],
        },
        {
          label: "administration",
          icon: "mdi-apps-box",
          val1: [
            { label: "User Information Setup", route: "/users" },
            /*  { label: "Issued Order", route: "/acInformation" },
            { label: "Outstanding Order Search", route: "/acInformation" }, */
          ],
        },
      ],
    };
  },
};
</script>
