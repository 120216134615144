import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import Home from "../views/Home.vue";
import login from "../views/login.vue";
import manuntencao from "../views/manuntencao.vue";
import acInformation from "../views/acInformation.vue";
import acinformationCadastro from "../views/acinformationCadastro.vue";
import atl from "../views/atl.vue";
import newTaskCard from "../views/newTaskCard.vue";
import newLogEntry from "../views/newLogEntry.vue";
import defectLogEntry from "../views/defectLogEntry.vue";
import aircraftLog from "../views/aircraftLog.vue";
import defectLogInfoSearch from "../views/defectLogInfoSearch.vue";
import users from "../views/users.vue";
import taskCardList from "../views/taskCardList.vue";
import newDmiRequest from "../views/newDmiRequest.vue";
import dmiRequestList from "../views/dmiRequestList.vue";
import checkPlanning from "../views/checkPlanning.vue";
import chekckPackage from "../views/chekckPackage.vue";
import checkSignOff from "../views/checkSignOff.vue";
import checkHistory from "../views/checkHistory.vue";
import tcLastDone from "../views/tcLastDone.vue";
import aircraftHoursCycles from "../views/aircraftHoursCycles.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: login,
  },
  {
    path: "/manuntencao",
    name: "manuntencao",
    component: manuntencao,
  },
  {
    path: "/acInformation",
    name: "acInformation",
    component: acInformation,
  },

  {
    path: "/acInformationCadastro",
    name: "acInformation",
    component: acinformationCadastro,
  },
  {
    path: "/atl",
    name: "atl",
    component: atl,
  },
  {
    path: "/newTaskCard",
    name: "newTaskCard",
    component: newTaskCard,
  },
  {
    path: "/taskCardList",
    name: "taskCardList",
    component: taskCardList,
  },
  {
    path: "/newLogEntry",
    name: "newLogEntry",
    component: newLogEntry,
  },
  {
    path: "/defectLogEntry",
    name: "defectLogEntry",
    component: defectLogEntry,
  },
  {
    path: "/newDmiRequest",
    name: "newDmiRequest",
    component: newDmiRequest,
  },
  {
    path: "/dmiRequestList",
    name: "dmiRequestList",
    component: dmiRequestList,
  },
  {
    path: "/aircraftLog",
    name: "aircraftLog",
    component: aircraftLog,
  },
  {
    path: "/defectLogInfoSearch",
    name: "defectLogInfoSearch",
    component: defectLogInfoSearch,
  },
  {
    path: "/checkPlanning",
    name: "checkPlanning",
    component: checkPlanning,
  },
  {
    path: "/chekckPackage",
    name: "chekckPackage",
    component: chekckPackage,
  },
  {
    path: "/checkSignOff",
    name: "checkSignOff",
    component: checkSignOff,
  },
  {
    path: "/checkHistory",
    name: "checkHistory",
    component: checkHistory,
  },
  {
    path: "/tcLastDone",
    name: "tcLastDone",
    component: tcLastDone,
  },
  {
    path: "/aircraftHoursCycles",
    name: "aircraftHoursCycles",
    component: aircraftHoursCycles,
  },
  {
    path: "/users",
    name: "users",
    component: users,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  if (
    window.sessionStorage.getItem("Username") === "null" ||
    !window.sessionStorage.getItem("Username")
  ) {
    store.dispatch("logout");
  } else {
    store.dispatch("login");
  }

  if (
    (to.fullPath == "/" &&
      window.sessionStorage.getItem("Username") === "null") ||
    !window.sessionStorage.getItem("Username")
  ) {
    router.push("/login");
  }
  if (
    to.fullPath == "/login" &&
    window.sessionStorage.getItem("Username") !== "null" &&
    window.sessionStorage.getItem("Username")
  ) {
    router.push("/");
  }

  if (store.state.to != to.fullPath) store.dispatch("clearEdit");
});

export default router;
