var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cabeca'),_c('acHoursCyclesFilter',{on:{"dados-atualizados":_vm.atualizarDados}}),_c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-card',{staticClass:"mx-auto pa-2 px-10 mb-10",attrs:{"outlined":"","color":"white"}},[_c('v-card-title',{staticClass:"pa-0",attrs:{"primary-title":""}},[_c('span',[_vm._v("AIRCRAFT HOURS AND CYCLES")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-inner-icon":"mdi-account-search","dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-10",attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.$store.state.dialog = true}}},[_c('v-icon',[_vm._v("mdi-filter-menu")])],1),_c('download-excel',{attrs:{"header":"Aircraft Log Status","name":"AC Accumulated Hours and Cycles List","data":_vm.desserts}},[_c('v-btn',{attrs:{"color":"success","icon":""}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1),_c('v-btn',{attrs:{"color":"info","icon":""},on:{"click":_vm.printList}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)],1),_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"fixed-header":"","dense":"","headers":_vm.headers,"items":_vm.$store.state.ac_accumulated_hours_and_cycles,"items-per-page":10},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"orange"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-edit")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"red white--text"},on:{"click":function($event){return _vm.remove(index, item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"item.flight_number",fn:function(ref){
var item = ref.item;
return [(item.file)?_c('a',{attrs:{"target":"_blank","href":_vm.$store.state.path2 + item.file}},[_vm._v(_vm._s(item.flight_number))]):_c('p',[_vm._v(_vm._s(item.flight_number))])]}},{key:"item.departure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.department ? ((item.department) + " - " + (item.arr)) : "")+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.generateNumer(item.id))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.date))+" ")]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.certificado,"color":"success","small":"","icon":"","target":"_blank","link":"","href":_vm.$store.state.path2 + item.fil}},[_c('v-icon',[_vm._v("mdi-file-cad")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }