import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    iconfont:'mdi',
    theme: {
        themes: {
          light: {
            primary: '#FF6028',
            // primary: '#409eff',
           /*  info: '#FB8C00 darken-1', */
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            white: '#ffffff',
          },
        },
      },
});
