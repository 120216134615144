<template>
  <div>
    <cabeca></cabeca>
    <acLogFilter @dados-atualizados="atualizarDados"></acLogFilter>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>Aircraft Log Status</span>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>

          <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/atl"
              >Add New ATL
            </v-btn>
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <v-btn color="red" icon>
              <label for="file">
                <v-icon>mdi-file-import</v-icon>
                <input
                  @change="importExcell"
                  type="file"
                  name=""
                  id="file"
                  style="display: none"
                />
              </label>
            </v-btn>
            <download-excel
              header="Aircraft Log Status"
              name="Users List"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <!--v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn-->
            <v-btn color="info" @click="printList" icon
              ><v-icon>mdi-printer</v-icon></v-btn
            >
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card flat>
          <v-data-table
            :search="search"
            fixed-header
            dense
            :headers="headers"
            :items="desserts"
            :items-per-page="10"
          >
            <template v-slot:item.options="{ item, index }">
              <v-btn
                @click="editar(item)"
                small
                icon
                class="mx-2"
                color="orange"
                ><v-icon>mdi-clipboard-edit</v-icon></v-btn
              >
              <v-btn
                @click="remove(index, item)"
                small
                icon
                class="mx-2"
                color="red white--text"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
            <!--  <template v-slot:item.upload="{ item }">
                    <v-btn color="orange" :disabled="!item.certificado" text small link :to="item.certificado"><v-icon>mdi-file</v-icon></v-btn>
                </template> -->
            <template v-slot:item.date="{ item }">
              {{ getDate(item.date) }}
            </template>
            <template v-slot:item.departure="{ item }">
              {{ item.department ? `${item.department} - ${item.arr}` : "" }}
            </template>
            <template v-slot:item.id="{ item }">
              {{ generateNumer(item.id) }}
            </template>
            <template v-slot:item.upload="{ item }">
              <v-btn
                :disabled="
                  item.certificado == null || item.certificado == 'null'
                    ? true
                    : false
                "
                color="success"
                small
                icon
                target="_blank"
                link
                :href="$store.state.path2 + item.certificado"
                ><v-icon>mdi-file-cad</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>

        <!-- 
              IPUT PARA ADICIONAR ARQUIVOS NO SISTEMA
              <v-file-input
              multiple

              @change="updateFileLink"
            ></v-file-input>
          -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import acLogFilter from "@/components/acLogFilter";
import readXlsxFile from "read-excel-file";

export default {
  components: {
    cabeca,
    acLogFilter,
  },

  data() {
    return {
      dados: {
        reg_no: "",
        log_page_number: "",
        from: "",
        to: "",
        department: "",
        arr: "",
      },
      search: null,

      dialog_print: false,
      dateRules: [
        (v) => !!v || "This field is required.",
        (v) => new Date(v) <= new Date() || "Date cannot be in the future.",
      ],
      maxDate: new Date().toISOString().split("T")[0],
      filtro: false,
      headers: [
        {
          text: "OPTIONS",
          value: "options",
          width: 130,
          class: "info white--text",
        },
        {
          text: "Uploaded File",
          align: "start",
          sortable: false,
          value: "upload",
          class: "info white--text",
          width: 120,
        },
        { text: "ID", value: "id", width: 100, class: "info white--text" },
        {
          text: "LOG PAGE NO",
          value: "log_page_number",
          width: 140,
          class: "info white--text",
        },
        {
          text: "REG_NO",
          value: "reg_number",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FLIGHT_NO",
          value: "fligh_number",
          width: 120,
          class: "info white--text",
        },
        {
          text: "D-A",
          value: "departure",
          width: 100,
          class: "info white--text",
        },
        {
          text: "Take Off Date",
          value: "date",
          width: 120,
          class: "info white--text",
        },
        {
          text: "Landing Date",
          value: "date2",
          width: 120,
          class: "info white--text",
        },
        {
          text: "BLOCK OFF",
          value: "block_off",
          width: 120,
          class: "info white--text",
        },
        {
          text: "TAKE OFF GMT",
          value: "t_o_gmt",
          width: 150,
          class: "info white--text",
        },
        {
          text: "LANDING GMT",
          value: "ldg_gmt",
          width: 150,
          class: "info white--text",
        },
        {
          text: "BLOCK ON",
          value: "block_on",
          width: 120,
          class: "info white--text",
        },
        {
          text: "BLOCK TIME",
          value: "block_time",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FLIGHT TIME",
          value: "flight_time",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FUEL ON BOARD",
          value: "fuel_on_board",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FUEL USED",
          value: "fuel_used",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FUEL REMAINING",
          value: "fuel_remaining",
          width: 100,
          class: "info white--text",
        },
        {
          text: "FUEL ADDED",
          value: "fuel_added",
          width: 100,
          class: "info white--text",
        },
        {
          text: "OIL / ENG 1",
          value: "engine1",
          width: 100,
          class: "info white--text",
        },
        /*  { text: 'ENG 1 QT/HR', value: 'iron', width:100, class: "info white--text" }, */
        {
          text: "OIL / ENG 2",
          value: "engine2",
          width: 100,
          class: "info white--text",
        },
      ],
    };
  },

  computed: {
    desserts() {
      return this.$store.state.atl;
    },
  },

  methods: {
    atualizarDados(novosDados) {
      this.dados = novosDados;
    },
    getTime(str) {
      let big = "";

      if (str.toString().includes("/")) big = str.split(" ")[2];
      else big = str;
      try {
        return big.trim();
      } catch (error) {
        return "00:00";
      }
    },
    printList() {
      const params = new URLSearchParams({
        reg_no: this.dados.reg_no,
        log_page_number: this.dados.log_page_number,
        from: this.dados.from,
        to: this.dados.to,
        department: this.dados.department,
        arr: this.dados.arr,
      });

      const url = `${this.$store.state.path}atl-print?${params.toString()}`;

      window.open(url);
    },

    async importExcell(file) {
      this.$store.dispatch("loading");

      let dis = this;

      readXlsxFile(file.target.files[0]).then((rows) => {
        rows.forEach((linha) => {
          try {
            const axios = require("axios");
            let form = new FormData();

            form.append("reg_number", "D2-TEH");
            form.append("log_page_number", linha[0] || "");
            form.append("fligh_number", linha[1] || "");
            form.append("department", linha[2] || "");
            form.append("arr", linha[3] || "");
            if (linha[4] && linha[4].toString().includes("/")) {
              let d = linha[4].split("/");

              form.append("date", d[2] + "/" + d[1] + "/" + d[0]);
            }
            if (
              linha[5] == "00:00:00" &&
              linha[0] != "TEST" &&
              linha[0] != "DELIVERY"
            ) {
              form.append("maintenance_only", "true");
            } else {
              form.append("maintenance_only", "");
            }
            form.append("block_off", linha[5] || "");
            form.append("t_o_gmt", linha[6] || "00:00:00");
            form.append("ldg_gmt", linha[7] || "");
            form.append("block_on", linha[8] || "");
            form.append("block_time", linha[9] || "");
            form.append("flight_time", linha[11] || "");
            form.append("fuel_on_board", linha[14] || "");
            form.append("fuel_used", linha[15] || "");
            form.append("fuel_remaining", linha[16] || "");
            form.append("fuel_added", linha[17] || "");
            form.append("engine1", linha[18] || "");
            form.append("engine2", linha[19] || "");
            form.append("certificado", "");

            form.append("date2", "");
            form.append("etops", "");
            form.append("rvsm", "");
            form.append("catii_iii_appr", "");
            form.append("manual_land", "");
            form.append("helice1", "");
            form.append("helice2", "");
            form.append("apu", "");
            form.append("hydra_add1", "");
            form.append("hydra_add2", "");
            form.append("h_helice1", "");
            form.append("h_helice2", "");
            form.append("hours_total", "");
            form.append("minutes_total", "");
            form.append("cycles_total", "");
            form.append("apu_inflight_start", "");
            form.append("attempted", "");
            form.append("leg", "");
            form.append("ata_chapter1", "");
            form.append("ata_section1", "");
            form.append("stn1", "");
            form.append("discrepancy1", "");
            form.append("pn_off1", "");
            form.append("sn_off1", "");
            form.append("defect_type1", "");
            form.append("discovered_by1", "");
            form.append("defect_date", "");
            form.append("a_ata_chapter1", "");
            form.append("a_ata_section1", "");
            form.append("a_stn1", "");
            form.append("a_corrective_action", "");
            form.append("a_pn_on", "");
            form.append("a_sn_on", "");
            form.append("a_etops_related", "");
            form.append("a_auto_related", "");
            form.append("a_engine_related", "");
            form.append("a_deferred", "");
            form.append("a_rii", "");
            form.append("a_corrected_by", "");
            form.append("a_rectified_date", "");
            form.append("ata_chapter2", "");
            form.append("ata_section2", "");
            form.append("stn2", "");
            form.append("discrepancy2", "");
            form.append("pn_off2", "");
            form.append("sn_off2", "");
            form.append("defect_type2", "");
            form.append("discovered_by2", "");
            form.append("defect_date2", "");
            form.append("a_ata_chapter2", "");
            form.append("a_ata_section2", "");
            form.append("a_stn2", "");
            form.append("a_corrective_action2", "");
            form.append("a_pn_on2", "");
            form.append("a_sn_on2", "");
            form.append("a_etops_related2", "");
            form.append("a_autoland_related2", "");
            form.append("a_engine_related2", "");
            form.append("a_deferred2", "");
            form.append("a_rii2", "");
            form.append("a_corrected_by2", "");
            form.append("a_rectified_date2", "");
            form.append("certificado", "");

            axios
              .post(dis.$store.state.path + "atl/", form)
              .then((response) => {
                this.$store.state.taskCard.push(response.data);
              });
          } catch (error) {
            console.log(error);
          }
        });

        dis.$store.dispatch("nloading");
      });
    },
    getDate(data) {
      try {
        return data.split(" ")[0];
      } catch (error) {
        return "";
      }
    },

    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/atl";
      this.$router.push("atl");
    },
    generateNumer(number) {
      let t = "";
      for (let i = 0; i <= 4 - number.toString().length; i++) {
        t += "0";
      }
      t += number;
      return t;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "atl/" + item.id.toString())
        .then(function () {
          store.dispatch("nloading");
          store.state.atl.splice(index, 1);
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function (error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },

    /* Funcao para carregar varios logs de arquivos */

    updateFileLink(arquivos) {
      let axios = require("axios");

      arquivos.forEach((arquivo) => {
        let arq = arquivo.name.split(".")[0];
        axios
          .post(this.$store.state.path + "updateAtlFile", {
            log_page_number: arq,
          })
          .then((response) => {
            console.log(response);
          });
      });
    },
  },
  mounted() {
    this.$store.state.dialog = true;

    this.$store.dispatch("getAircraftInformation");
    //this.$store.dispatch("getAtl", this.$store);
    this.$store.dispatch("getAcType");
    this.$store.state.atl = [];
  },
};
</script>

<style>
</style>