<template>
  <app-layout>
    <div>
      <!--cabeca></cabeca-->

      <v-container grid-list-xs>
        <!-- MODAL Imprimir -->
        <v-dialog v-model="dialog_print" width="400">
          <v-card>
            <v-card-title>
              <span class="text-h5">Print Listing</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="From*"
                      v-model="imprimir.from"
                      :max="maxDate"
                      type="date"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="To*"
                      v-model="imprimir.to"
                      :max="maxDate"
                      type="date"
                      outlined
                      dense
                      :rules="dateRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*Indicates mandatory fields.</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="dialog_print = false">
                Cancel
              </v-btn>
              <v-btn color="success" target="_blank" @click="printList">
                <v-icon dark> mdi-printer</v-icon> Print
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
          <v-card-title class="pa-0" primary-title>
            <span>{{ $t("acInformation") }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-account-search"
              dense
              outlined
              :label="$t('search')"
              class="ma-0 pa-0"
            ></v-text-field>

            <v-row class="justify-end">
              <v-btn
                color="info"
                class="mr-2 mt-1"
                depressed
                small
                route
                to="/acInformationCadastro"
                >{{ $t("addNewAC") }}</v-btn
              >
              <v-btn color="primary" class="mr-10" icon>
                <v-icon>mdi-filter-menu</v-icon></v-btn
              >
              <download-excel
                :header="$t('acInformation')"
                name="acInformation"
                :data="desserts"
                ><v-btn color="success" icon
                  ><v-icon>mdi-file-excel</v-icon></v-btn
                ></download-excel
              >
              <!--v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn-->
              <v-btn color="info" @click="dialog_print = true" icon
                ><v-icon>mdi-printer</v-icon></v-btn
              >
            </v-row>
          </v-card-title>
          <v-divider></v-divider>

          <v-card flat>
            <v-data-table
              :search="search"
              fixed-header
              dense
              :headers="headers"
              :items="desserts"
              :items-per-page="10"
            >
              <template v-slot:item.options="{ item, index }">
                <v-btn
                  @click="editar(item)"
                  small
                  icon
                  class="mx-2"
                  color="orange"
                  ><v-icon>mdi-clipboard-edit</v-icon></v-btn
                >
                <v-btn
                  @click="remove(index, item)"
                  small
                  icon
                  class="mx-2"
                  color="red white--text"
                  ><v-icon>mdi-trash-can</v-icon></v-btn
                >
              </template>
              <template v-slot:item.certificate="{ item }">
                <v-btn
                  :disabled="
                    item.certificate == null ||
                    item.certificate == 'null' ||
                    item.certificate == '' ||
                    item.certificate == 'undefined'
                      ? true
                      : false
                  "
                  color="success"
                  small
                  icon
                  target="_blank"
                  link
                  :href="$store.state.path2 + item.certificate"
                  ><v-icon>mdi-file-cad</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-container>
    </div>
  </app-layout>
</template>

<script>
//import cabeca from "@/components/cabeca";
import AppLayout from "@/components/AppLayout";
export default {
  components: {
    //cabeca,
    AppLayout,
  },

  data() {
    return {
      search: null,
      imprimir: { from: "", to: "" },
      dialog_print: false,
      dateRules: [
        (v) => !!v || "This field is required.",
        (v) => new Date(v) <= new Date() || "Date cannot be in the future.",
      ],
      maxDate: new Date().toISOString().split("T")[0],
      headers: [
        {
          text: "Options",
          value: "options",
          class: "info white--text",
          width: 140,
        },
        {
          text: "CONFIG",
          align: "start",
          value: "config",
          class: "info white--text",
          width: 100,
        },
        { text: "MSN", value: "msn", class: "info white--text", width: 100 },
        {
          text: "REG NO",
          value: "reg_no",
          class: "info white--text",
          width: 100,
        },
        {
          text: "TAIL NO",
          value: "tail_no",
          class: "info white--text",
          width: 100,
        },
        {
          text: "LINE NO",
          value: "line_no",
          class: "info white--text",
          width: 100,
        },
        {
          text: "EFF NO",
          value: "eff_no",
          class: "info white--text",
          width: 100,
        },
        {
          text: "IPC NO",
          value: "ipc_no",
          class: "info white--text",
          width: 100,
        },
        {
          text: "MFG DATE",
          value: "mfg_date",
          class: "info white--text",
          width: 140,
        },
        {
          text: "LOG DATE",
          value: "log_date",
          class: "info white--text",
          width: 140,
        },
        {
          text: "TOTAL HOURS",
          value: "total_Hours",
          class: "info white--text",
          width: 140,
        },
        {
          text: "TOTAL MINUTES",
          value: "total_minutes",
          class: "info white--text",
          width: 150,
        },
        {
          text: "TOTAL CYCLES",
          value: "total_cycles",
          class: "info white--text",
          width: 140,
        },
        {
          text: "ACTIVE",
          value: "active",
          class: "info white--text",
          width: 140,
        },
        {
          text: "HOURS DAY",
          value: "hours_day",
          class: "info white--text",
          width: 140,
        },
        {
          text: "CYCLES DAY",
          value: "cycles_day",
          class: "info white--text",
          width: 140,
        },
        {
          text: "REG EXPIRACY",
          value: "reg_expiracy",
          class: "info white--text",
          width: 140,
        },
        {
          text: "STATUS",
          value: "status",
          class: "info white--text",
          width: 140,
        },
        {
          text: "CERTIFICATION",
          value: "certificate",
          class: "info white--text",
          width: 140,
        },
        {
          text: "Model",
          value: "model",
          class: "info white--text",
          width: 140,
        },
      ],
    };
  },

  computed: {
    desserts() {
      return this.$store.state.acInformation;
    },
  },

  methods: {
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "acInformation/" + item.id.toString())
        .then(function () {
          store.state.acInformation.splice(index, 1);
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function (error) {
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    printList() {
      window.open(
        this.$store.state.path +
          "list-aircraft-information?from=" +
          this.imprimir.from +
          "&to=" +
          this.imprimir.to
      );
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },
    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/acInformationCadastro";
      this.$router.push("acInformationCadastro");
    },
  },
  mounted() {
    this.$store.dispatch("getAircraftInformation");
  },
};
</script>

<style>
</style>