<template>
  <div style="width:100%; height:100vh !important;" class="bg777">

      <div class="fixed">
          <v-col class=" d-flex justify-center align-center mt-10" width="100vh"  height="100%">
          <v-card color="white" class="rounded-lg pa-5 d-flex justify-center align-center" width="650px" min-height="400px"> 
              <v-col class=" align-center d-flex flex-column">

                  <v-img width="200px"  src="@/assets/images/taag3.png">

                  </v-img>

                  <h1>TAAG-VME</h1>
              <v-text-field
                  name="Username"
                  label="Username"
                  hint="Nome de utilizador"
                  dense
                  outlined
                  class="mt-10"
                  v-model="username"
                  prepend-inner-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                  name="Senha"
                  label="Senha"
                  hint="Informe a sua senha"
                  dense
                  outlined
                  class="mt-5"
                  v-model="password"
                  prepend-inner-icon="mdi-form-textbox-password"
                  type="password"
              ></v-text-field>

              <v-subheader class="mb-5">Preencha todos os dados devidamente e clica em "Entrar"</v-subheader>

              <v-btn route to="/" color="primary" @click="login" class="elevation-0">Entrar <v-icon class="ml-4">mdi-page-next-outline</v-icon></v-btn>
              </v-col>
              
          </v-card>
      </v-col>
      </div>
  </div>
</template>

<script>

export default {

    components:{
    },

    data(){
        return{
            username:'',
            password:''
        }
    },
    methods:{
      login(){


       const axios = require('axios');

       this.$store.dispatch("loading")
       let store =  this.$store;
       let router =  this.$router;
      const Swal = require('sweetalert2');
      

       axios.post(this.$store.state.path+'login', {
          username: this.username,
          password: this.password,
        })
        .then(function (response) {

            if(typeof response.data == 'string')
              Swal.fire({title:"Erro!", text:response.data, icon: 'warning',});
            else{

              store.state.user = response.data;
              sessionStorage.setItem("Username","Filipe Lukebana");
              store.dispatch("login");
               store.state.logged = true;
              router.push("/");
            }

          store.dispatch("nloading")
        })
        .catch(function (error) {
           console.log(error)
           store.dispatch("nloading")
        });

 
       
     }
    }

}
</script>

<style>

</style>