<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Log Search</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-autocomplete
                  dense
                  outlined
                  v-model="dados.reg_no"
                  @input="updateData"
                  label="Reg Number"
                  :items="$store.state.acInformation"
                  item-text="reg_no"
                  item-value="reg_no"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date Start"
                  type="date"
                  @input="updateData"
                  dense
                  outlined
                  v-model="dados.from"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date End"
                  type="date"
                  dense
                  outlined
                  @input="updateData"
                  v-model="dados.to"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="($store.state.dialog = false), clear()"
          >
            Close
          </v-btn>
          <v-btn color="success darken-1" elevation="0" @click="retrieveAll()">
            Retrieve All
          </v-btn>
          <v-btn color="info darken-1" elevation="0" @click="retrieve()">
            Retrieve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    /* dialog: false, */
    dados: {
      reg_no: "",
      defect_no: "",
      from: "",
      to: "",
      ata: "",
      defect_type: "",
      key_word: "",
    },
  }),
  methods: {
    updateData() {
      this.$emit("dados-atualizados", { ...this.dados });
    },
    clear() {
      this.dados = {
        reg_no: "",
        defect_no: "",
        from: "",
        to: "",
        ata: "",
        defect_type: "",
        key_word: "",
      };
    },
    retrieve() {
      this.$store.dispatch("filteACHoursCycles", this.dados);

      this.$store.state.dialog = false;
    },
    retrieveAll() {
      this.$store.dispatch("getACHoursCycles");

      this.$store.state.dialog = false;
    },
  },
  computed: {
    dialog() {
      return this.$store.state.dialog;
    },
  },
};
</script>
