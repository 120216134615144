var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-layout',[_c('div',{attrs:{"dense":""}},[_c('v-container',{attrs:{"grid-list-xs":""}},[_c('h3',[_vm._v(_vm._s(_vm.$t("home")))]),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"3"}},_vm._l((_vm.types),function(type,index){return _c('div',{key:type},[_c('div',{staticClass:"grey pa-2 lighten-1"},[_c('h5',[_vm._v(_vm._s(type)+" A/C STATUS")])]),_c('el-table',{staticClass:"md-10",staticStyle:{"width":"100%"},attrs:{"empty-text":"No data","data":_vm.aricrafts[index],"stripe":"","size":"small","border":""}},[_c('el-table-column',{attrs:{"prop":"reg_no","label":"Reg No"}}),_c('el-table-column',{attrs:{"prop":"log_date","label":"Log Date"}})],1)],1)}),0),_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"grey pa-2 lighten-1"},[_c('h5',[_vm._v(_vm._s(_vm.$t("openDmiList")))])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.open_dmis_list_headers,"items":_vm.$store.state.dmiRequestO,"dense":""},scopedSlots:_vm._u([{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-2 py-0",attrs:{"label":"","color":!item.closed_date ||
                  item.closed_date != 'null' ||
                  item.closed_date != null
                    ? _vm.getColor(
                        item.data_discovery,
                        _vm.getDate(
                          item.due_date
                            ? item.due_date
                            : item.mel
                            ? item.mel
                            : null,
                          item.created_at
                        )
                      )
                    : 'white black--text',"dark":""}},[_vm._v(" "+_vm._s(_vm.getDate( item.due_date ? item.due_date : item.mel ? item.mel : null, item.created_at.toString().split(" ")[0] ))+" ")])]}}])}),_c('div',{staticClass:"grey pa-2 lighten-1"},[_c('h5',[_vm._v("A/C Check Status")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.acCheckStatus_headers,"items":_vm.acCheckStatus,"dense":""},scopedSlots:_vm._u([{key:"item.nex_due",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"px-2 py-0",attrs:{"label":"","color":_vm.getColor(item.output_date, item.nex_due),"dark":""}},[_vm._v(" "+_vm._s(item.nex_due)+" ")])]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }