<template>
  <div>
    <cabeca></cabeca>
    <acHoursCyclesFilter
      @dados-atualizados="atualizarDados"
    ></acHoursCyclesFilter>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>AIRCRAFT HOURS AND CYCLES</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>

          <v-row class="justify-end">
            <!-- <v-btn color="info" class="mr-2 mt-1" depressed small route to="/atl">Add New ATL </v-btn> -->
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <download-excel
              header="Aircraft Log Status"
              name="AC Accumulated Hours and Cycles List"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <!--v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn-->
            <v-btn color="info" @click="printList" icon
              ><v-icon>mdi-printer</v-icon></v-btn
            >
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card flat>
          <v-data-table
            :search="search"
            fixed-header
            dense
            :headers="headers"
            :items="$store.state.ac_accumulated_hours_and_cycles"
            :items-per-page="10"
          >
            <template v-slot:item.options="{ item, index }">
              <v-btn
                @click="editar(item)"
                small
                icon
                class="mx-2"
                color="orange"
                ><v-icon>mdi-clipboard-edit</v-icon></v-btn
              >
              <v-btn
                @click="remove(index, item)"
                small
                icon
                class="mx-2"
                color="red white--text"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
            <!--  <template v-slot:item.upload="{ item }">
                    <v-btn color="orange" :disabled="!item.certificado" text small link :to="item.certificado"><v-icon>mdi-file</v-icon></v-btn>
                </template> -->
            <template v-slot:item.flight_number="{ item }">
              <a
                target="_blank"
                v-if="item.file"
                :href="$store.state.path2 + item.file"
                >{{ item.flight_number }}</a
              >
              <p v-else>{{ item.flight_number }}</p>
            </template>
            <template v-slot:item.departure="{ item }">
              {{ item.department ? `${item.department} - ${item.arr}` : "" }}
            </template>
            <!-- <template v-slot:item.flight_t="{ item }">
                    {{hoursToMinute(item.flight_t)}}
                </template> -->
            <template v-slot:item.id="{ item }">
              {{ generateNumer(item.id) }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ getDate(item.date) }}
            </template>
            <template v-slot:item.upload="{ item }">
              <v-btn
                :disabled="!item.certificado"
                color="success"
                small
                icon
                target="_blank"
                link
                :href="$store.state.path2 + item.fil"
                ><v-icon>mdi-file-cad</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import acHoursCyclesFilter from "@/components/acHoursCyclesFilter";
export default {
  components: {
    cabeca,
    acHoursCyclesFilter,
  },

  data() {
    return {
      /*  ac_accumulated_hours_and_cycles:[], */
      search: null,
      filtro: false,
      dados: {
        reg_no: "",
        defect_no: "",
        from: "",
        to: "",
        ata: "",
        defect_type: "",
        key_word: "",
      },
      headers: [
        {
          text: "Reg No",
          align: "start",
          sortable: false,
          value: "reg_no",
          class: "info white--text",
          width: 120,
        },
        {
          text: "Flight No",
          value: "flight_number",
          width: 100,
          class: "info white--text",
        },
        {
          text: "Flight Time",
          value: "flight_t",
          width: 140,
          class: "info white--text",
        },
        {
          text: "Total Hours",
          value: "total_hours",
          width: 120,
          class: "info white--text",
        },
        {
          text: "Total Cycles",
          value: "total_cycles",
          width: 120,
          class: "info white--text",
        },
        {
          text: "Log Page No",
          value: "log_page_no",
          width: 120,
          class: "info white--text",
        },
        { text: "Date", value: "date", width: 140, class: "info white--text" },
        {
          text: "Engine Hours 1",
          value: "eng_hours_1",
          width: 140,
          class: "info white--text",
        },
        {
          text: "Engine Cycles 1",
          value: "eng_cycles_1",
          width: 140,
          class: "info white--text",
        },
        {
          text: "Engine Hours 2",
          value: "eng_hours_2",
          width: 140,
          class: "info white--text",
        },
        {
          text: "Engine Cycles 2",
          value: "eng_cycles_2",
          width: 150,
          class: "info white--text",
        },
        /*  { text: 'LANDING GMT', value: 'ldg_gmt', width:150, class: "info white--text" },
          { text: 'BLOCK ON', value: 'block_on', width:120, class: "info white--text" },
          { text: 'BLOCK TIME', value: 'block_time', width:100, class: "info white--text" },
          { text: 'FLIGHT TIME', value: 'flight_time', width:100, class: "info white--text" },
          { text: 'FUEL ON BOARD', value: 'fuel_on_board', width:100, class: "info white--text" },
          { text: 'FUEL USED', value: 'fuel_used', width:100, class: "info white--text" },
          { text: 'FUEL REMAINING', value: 'fuel_remaining', width:100, class: "info white--text" },
          { text: 'FUEL ADDED', value: 'fuel_added', width:100, class: "info white--text" },
          { text: 'OIL / ENG 1', value: 'engine1', width:100, class: "info white--text" },
          { text: 'ENG 1 QT/HR', value: 'iron', width:100, class: "info white--text" },
          { text: 'OIL / ENG 2', value: 'engine2', width:100, class: "info white--text" }, */
        /*  { text: 'OPTIONS', value: 'options', width:130, class: "info white--text" }, */
      ],
    };
  },

  computed: {
    desserts() {
      return this.$store.state.ac_accumulated_hours_and_cycles;
    },
  },

  methods: {
    atualizarDados(novosDados) {
      this.dados = novosDados;
    },
    printList() {
      const params = new URLSearchParams({
        reg_no: this.dados.reg_no,
        defect_no: this.dados.defect_no,
        from: this.dados.from,
        to: this.dados.to,
        ata: this.dados.ata,
        defect_type: this.dados.defect_type,
        key_word: this.dados.key_word,
      });

      const url = `${
        this.$store.state.path
      }aircraft-hours-cycles-print?${params.toString()}`;

      window.open(url);
    },
    hoursToMinute(hours) {
      var hms = hours; // your input string
      var a = hms.split(":"); // split it at the colons

      // Hours are worth 60 minutes.
      var minutes = +a[0] * 60 + +a[1];

      return minutes;
    },
    getDate(data) {
      try {
        return data.toString().split(" ")[0];
      } catch (error) {
        console.log(error);
        return "";
      }
    },

    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/atl";
      this.$router.push("atl");
    },
    generateNumer(number) {
      let t = "";
      for (let i = 0; i <= 4 - number.toString().length; i++) {
        t += "0";
      }
      t += number;
      return t;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "atl/" + item.id.toString())
        .then(function () {
          store.dispatch("nloading");
          store.state.atl.splice(index, 1);
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function (error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },
  },
  mounted() {
    this.$store.state.dialog = true;

    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getAtl", this.$store);
    this.$store.dispatch("getAcType");
  },
};
</script>

<style>
</style>